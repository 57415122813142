import React from 'react'

import SmartHeader from '../components/SmartHeader/SmartHeader'
import SmartExplain from '../components/SmartExplain/SmartExplain'
import ConnectTrustWalletButton from './ConnectTrustWalletButton'
const SmartPage = () => {
  return (
    <>
      <SmartHeader/><br/><br/><br/><br/><br/>
      <SmartExplain/>
      <ConnectTrustWalletButton/>
    </>
  )
}

export default SmartPage
