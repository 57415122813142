import React from 'react'
import './Faq.css'
const Faq = () => {
  return (
    
      <section id="faq" className="faq-area">
    <div className="container custom-container-four">
      <div className="faq-shape-wrap">
        <img src="/assets/img/images/faq_shape01.png" alt="" className="img-one"/>
        <img src="/assets/img/images/faq_shape02.png" alt="" className="img-two rotateme"/>
        <img src="/assets/img/images/faq_shape03.png" alt="" className="img-three"/>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title section-title-two text-center mb-60">
            <h2 className="title">Frequently asked questions</h2>
          </div>
          <div className="faq-wrap wow fadeInUp" data-wow-delay=".2s">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                          aria-expanded="true" aria-controls="collapseOne">
                          WHY INVEST?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                     data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>1-New project with new concept
                    <br/>
                    2-profit sharing from 1st month
                    <br/>
                    3-Staking platform with new ideas & Concept
                    <br/>
                    4-Strict vesting schedule
                    <br/>
                    5-Integrated solution for traders
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    WHAT IS TOKEN UTILITIES?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                     data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <p>1-Trading fees By WTE
                    <br/>
                    2-ICO Events "Staking certain Value of WTE for project owners & Participants"
                    <br/>
                    3-Tracking wallets Dapps Fees in WTE
                    <br/>
                    4-Payment BY WTE in WTE staking Platform
                    <br/>
                    5-By holding WTE having partial and full access of services in NFT world
                    </p>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    
  )
}

export default Faq
