
import './Choose.css'

const Choose = () => {
  return (
    <div>
      <section className="choose-area-two choose-bg pb-130 bg-light">
    <div className="container custom-container-four">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="section-title text-center mb-70">
            <span className="sub-title">why Choose us</span>
            <h2 className="title">Why you choose WTE</h2><br/>
          </div>
        </div>
      </div>
      <br/>
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <div className="choose-item-two wow fadeInUp" data-wow-delay=".2s">
            <div className="choose-icon-two">
              <img src="/assets/img/icon/h2_choose_icon01.svg" alt=""/>
            </div>
            <div className="choose-content">
              <h2 className="title"><a href="test">Expert Crypto traders</a></h2>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="choose-item-two wow fadeInUp" data-wow-delay=".4s">
            <div className="choose-icon-two">
              <img src="/assets/img/icon/h2_choose_icon02.svg" alt=""/>
            </div>
            <div className="choose-content">
              <h2 className="title"><a href="test">NFT project</a></h2>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="choose-item-two wow fadeInUp" data-wow-delay=".6s">
            <div className="choose-icon-two">
              <img src="/assets/img/icon/h2_choose_icon03.svg" alt=""/>
            </div>
            <div className="choose-content">
              <h2 className="title"><a href="test">New traders</a></h2>
            </div>
          </div>
        </div>
        
        <div className="col-lg-3 col-sm-6">
          <div className="choose-item-two wow fadeInUp" data-wow-delay=".8s">
            <div className="choose-icon-two">
              <img src="/assets/img/icon/h2_choose_icon04.svg" alt=""/>
            </div>
            <div className="choose-content">
              <h2 className="title"><a href="test">Cybersecurity specialists</a></h2>
            </div>
          </div>
        </div>
        <div className="choose-item-two wow fadeInUp" data-wow-delay=".4s">
            <div className="choose-icon-two">
              <img src="/assets/img/icon/h2_choose_icon02.svg" alt=""/>
            </div>
            <div className="choose-content">
              <h2 className="title"><a href="test">E-commerce</a></h2>
            </div>
          </div>
      </div>
    </div>
  </section>
    </div>
  )
}

export default Choose
