// PhotoModal.js
import React, { useState } from 'react';
import './PhotoModal.css'; // Import your CSS file

const PhotoModal = ({ photoUrl, altText }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="photo-modal">
      <img
        src={photoUrl}
        alt={altText}
        className="thumbnail"
        onClick={openModal}
      />

      {modalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <img src={photoUrl} alt={altText} className="modal-photo" />
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoModal;
