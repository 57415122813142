import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div>
      <footer>
            <div className="footer-area-two">
                <div className="container custom-container-four">
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="footer-menu-two">
                                    <ul className="navigation">
                                        <li><a href="#about">About us</a></li>
                                        <li><a href="#roadmap">Roadmap</a></li>
                                        <li><a href="#contact">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="footer-social">
                                    <ul>
                                        <li><a href="test"><i className="fab fa-facebook-square"></i></a></li>
                                        <li><a href="test"><i className="fab fa-twitter"></i></a></li>
                                        <li><a href="test"><i className="fab fa-vimeo-v"></i></a></li>
                                        <li><a href="test"><i className="fab fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="copyright-text">
                                    <p>Copyright &copy; 2023. All Rights Reserved WTE</p>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                
                            </div>
                            <div className="col-lg-5">
                                <div className="footer-bottom-menu">
                                    <ul>
                                        <li><a href="test">Terms of Service</a></li>
                                        <li><a href="test">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer
