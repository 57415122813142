import { useRouteError } from "react-router-dom";



export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <div>
      <h1>Error</h1>
      <p>Something went wrong: {error.message}</p>
    </div>
    </div>
  );
}