import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
    <div>
      <section id="contact" className="contact-aera contact-bg">
    <div className="container custom-container-four">
      <div className="contact-inner">
        <div className="row">
          <div className="col-lg-6">
            <div className="contact-info-wrap-two wow fadeInLeft" data-wow-delay=".2s">
              <h2 className="title">Get in Touch</h2>
              <div className="contact-list-item">
                <div className="icon">
                  <img src="/assets/img/icon/contact_icon01.svg" alt=""/>
                </div>
                <div className="content">
                  <p>Office No. 43-44
                  Owned by Dubai Municipality
                  Bur Dubai- Al Fahidi</p>
                </div>
              </div>
              <div className="contact-list-item">
                <div className="icon">
                  <img src="/assets/img/icon/contact_icon01.svg" alt=""/>
                </div>
                <div className="content">
                  <p>admin@wtenergy.tech</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-form-wrap-two wow fadeInRight" data-wow-delay=".2s">
              <h2 className="title">Lets Get in touch with us</h2>
              <form action="{{route('message.store')}}" method="post">
                @csrf
                <input type="text" placeholder="Name" name="name"  required/>
                <input type="email" placeholder="Email Address"  name="email" required/>
                <textarea  placeholder="Massage" name="message"  required></textarea>
                <button type="submit" className="btn btn-two">Send Massage</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
  )
}

export default Contact
