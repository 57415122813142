import React from 'react'
import './About.css'

const About = () => {
  return (
    <div>
      <section id="about" className="about-area-two bg-light" >
    <div className="container custom-container-four">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div className="about-img-two text-center wow fadeInLeft" data-wow-delay=".2s">
            <img src="/assets/img/images/h2_about_img.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-content-two wow fadeInRight" data-wow-delay=".2s">
            <div className="section-title section-title-two mb-15">
              <span className="sub-title">ABOUT US</span>
              <h2 className="title">ABOUT WTE</h2>
            </div>
            <p>
            First projects have dedicated Platforms to provide integrated solutions for traders communities and ensure Giving them full <br/> and real informations about nominated tokens to facilitate for them trading, decision making, copy trading and profit calculations</p>
            <div className="about-list">
              <ul>  
              </ul>
            </div>
            <a href="/assets/img/images/h2_about_img.png" download target="_blank" className="btn btn-two">Download Document</a>
          </div>
        </div>
      </div>
    </div>
    <div className="about-shape-wrap">
      <img src="/assets/img/images/about_shape01.png" alt="" className="shape-one" />
      <img src="/assets/img/images/about_shape02.png" alt="" className="shape-two rotateme"/>
    </div>
  </section>
    </div>
  )
}

export default About
