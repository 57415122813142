import React from 'react'
import  Header  from '../components/Header/header'
import  Container  from '../components/Container/Container'
import Banner from '../components/Banner/Banner'
import CountdownTime from '../components/CountdownTime/CountdownTime'
import About from '../components/About/About'
import Choose from '../components/Choose/Choose'
import Roadmap from '../components/Roadmap/Roadmap'
import Faq from '../components/Faq/Faq'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'





const Home = () => {
  const targetDate = new Date('2024-01-30T23:59:59');
  return (
    <>
      <Header/>
      <Container>
      <Banner/>
      <CountdownTime targetDate={targetDate}/>
      <About/>
      <Choose/>
      {/*<Counter/>*/}
      <Roadmap/>
      <Faq/>
      <Contact/>
      </Container>
      <Footer/>
    </>
  )
}

export default Home
