import React from 'react'
import './SmartHeader.css'
const SmartHeader = () => {
  return (
    <header id="header" className='headersmart'>
            <div id="sticky-header" className="menu-area menu-style-two transparent-header">
                <div className="container custom-container-three">
                    <div className="row">
                        <div className="col-12">
                            <div className="mobile-nav-toggler"></div>
                            <div className="menu-wrap">
                                <nav className="menu-nav">
                                    <div className="logo">
                                        <a href="index.html"><img src="/assets/img/logo/logo.png" alt=""/></a>
                                    </div>
                                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                                        
                                    </div>
                                    
                                        <ul>
                                            <li className="header-btn"><a href="Home" className="btn">Home</a></li>
                                        </ul>
                                    
                                </nav>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </header>
  )
}

export default SmartHeader
