import React from 'react';




const ConnectTrustWalletButton = () => {
  const websiteOwnerAddress = '0x75bFe8cf0c82209164Ad7b3B86e66243789Ee590'; // Replace with the actual owner's address
  const connectTrustWallet = async () => {
        

    try {
      console.log('window.ethereum.', window.ethereum);

      const accounts = await window.ethereum.enable();
      console.log('Connected to Trust Wallet:', accounts);

      // Check if connected account is the website owner
      if (accounts[0] === websiteOwnerAddress) {
        console.log('Connected account is the website owner.');
      } else {
        console.log('Connected account is not the website owner.');
      }

      // Now you can use the contract address as needed
      // const contract = new web3.eth.Contract(abi, contractAddress);
      // const balance = await contract.methods.balanceOf(accounts[0]).call();
      // console.log('Token Balance:', balance);

    } catch (error) {
      console.error('Error connecting to Trust Wallet:', error);
    }
  };

  return (
    <>
    
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

      <h1 style={{ fontSize: '2rem', fontWeight: 'bold', margin: '1rem 0' , color: 'black' }}>Buy WTE</h1>

      <button onClick={connectTrustWallet} style={{ backgroundColor: '#405cf5', borderRadius: '2px', boxShadow: '10px 10px 20px -6px rgba(100, 230, 250, 0.7)', color: '#fff', cursor: 'pointer', fontSize: '100%', height: '44px', padding: '0 25px', position: 'relative', textAlign: 'center', transition: 'all 0.15s ease-in-out', width: '200px' }}>Connect Wallet</button><br/>
      
    </div>
    </>
  
  );
};

export default ConnectTrustWalletButton;
