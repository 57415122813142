import React from 'react'
import './Countdown.css'
import Countdown from 'react-countdown';

const CountdownTime = ({ targetDate }) => {
  return (
    <div>
     
            <section id="countdown" className="countdown-area-two">
                <div className="container custom-container-four">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="countdown-wrap">
                                <h2 className="title">WTE private sale Will Start In..</h2>
                                <Countdown
                                date={targetDate}
                                renderer={({ days, hours, minutes, seconds, completed }) => {
                                    if (completed) {
                                    // Countdown has completed
                                    return <span>Countdown expired!</span>;
                                    } else {
                                    return (
                                        <div className="countdown-container">
                                        <div className="countdown-item">
                                            <span className="countdown-circle" style={{ backgroundColor: '#3498db' }}>{days}</span>
                                            <span className="countdown-label" >Days</span>
                                        </div>
                                        <div className="countdown-item">
                                            <span className="countdown-circle" style={{ backgroundColor: '#e74c3c' }}>{hours}</span>
                                            <span className="countdown-label">Hours</span>
                                        </div>
                                        <div className="countdown-item">
                                            <span className="countdown-circle" style={{ backgroundColor: '#2ecc71' }}>{minutes}</span>
                                            <span className="countdown-label">Minutes</span>
                                        </div>
                                        <div className="countdown-item">
                                            <span className="countdown-circle" style={{ backgroundColor: '#f39c12' }}>{seconds}</span>
                                            <span className="countdown-label">Seconds</span>
                                        </div>
                                        </div>
                                    );
                                    }
                                }}
                                />           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
    </div>
  )
}

export default CountdownTime
