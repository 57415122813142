import React from 'react'

const Banner = () => {
  return (
    <div>
       {/* banner-area */}
            <section className="banner-area-two">
                <div className="banner-bg-two"></div>
                <div className="container custom-container-four">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="banner-content text-center">
                                <h2 className="title wow fadeInDown" data-wow-delay=".2s">Wonder Energy & Technology be used seamlessly to support the society
</h2>
                                <img src="/assets/img/banner/banner_img.png" alt="img"/>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </section>
            {/* banner-area-end */}
    </div>
  )
}

export default Banner
