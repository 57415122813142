
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import SmartPage from './Pages/SmartPage';
import ErrorPage from './ErrorPage.jsx'
function App() {

  
  return (
    <BrowserRouter>
    <Routes>
    <Route index element={<Home/>}/>
      <Route path='/Home' element={<Home/>}/>
      <Route path='/SmartPage' element={<SmartPage/>}/>
      <Route path='*' element={<ErrorPage/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
