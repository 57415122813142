import React from 'react'

import './SmartExplain.css'
import PhotoModal from '../PhotoModal/PhotoModal';
const SmartExplain = () => {
    const copyCode = () => {
        const codeElement = document.querySelector('.code-snippet code');
        const textArea = document.createElement('textarea');
        textArea.value = codeElement.innerText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert('Code copied to clipboard!');
        };
  return (
    <div className="page-container">
      <h1 className='text-dark'>Steps for explain download trust wallet application and choose wte currency</h1><br/>
      <span>
      Let’s take a step-by-step guide on how to set up the Trust Wallet app on your mobile device.<br/><br/>

        Go to https://trustwallet.com/download to install Trust Wallet.<br/><br/>

        Then, open the app and click on ‘Get Started’ to start the setup process.<br/><br/>
      </span>
      
      <div className="photo-section">
      <PhotoModal
        photoUrl="/assets/img/images/explain1.png"
        altText="Photo1"
      />
      </div>
      <br/><br/><br/>
      <span>
      By clicking on ‘Get Started,’ you will automatically show that you agree and consent to Trust Wallet’s ‘Terms of Service’ and ‘Privacy Policy’.
      <br/><br/>
        Next, select ‘Create a new wallet.’ Trust Wallet will require you to back up your secret phrase. You can choose to back up your secret phrase manually or to Google Drive, iCloud, in the case of iOS devices.
        <br/><br/>
        For this guide, we’ll select the ‘Back up manually’ option.<br/><br/>
      </span>
      <div className="photo-section">
      <PhotoModal
        photoUrl="/assets/img/images/explain2.png"
        altText="Photo1"
      />
      </div><br/><br/>
      <span>
      When you create a new wallet, this main page will appear to you
      <br/><br/>
      Press the plus button at the top 
       <br/><br/>
       The next step is to click on the plus sign at the top of the page<br/><br/>
      </span>
      <div className="photo-section">
      <PhotoModal
        photoUrl="/assets/img/images/explain3.png"
        altText="Photo1"
      />
      </div>
      <br/><br/>
      <span>
      Choosing the BNP Smart Chain network instead of the Ethereum network
      <br/><br/>
      </span>
      <div className="photo-section">
      <PhotoModal
        photoUrl="/assets/img/images/explain4.png"
        altText="Photo1"
      />
      </div>
      <br/><br/>
      <span>
      Now click on Copy the address below and paste it in place of the address
      <br/><br/>
      Then continue pressing import<br/><br/>
      <div className="chat-bubble assistant">
        <span>Copy this address for the contract</span>
        <div className="code-snippet">
          <pre>
            <code>
              {'0x1e3783Ea1658B7973dfD94E3E283bA51342ae189'}
                </code>
                </pre>
                <button onClick={copyCode}>Copy</button>
                </div>
                </div>
                <pre/>
       <br/><br/>
      </span>
      <div className="photo-section">
      <PhotoModal
        photoUrl="/assets/img/images/explain5.png"
        altText="Photo1"
      />
      </div>
      <br/><br/>
      
      
      
    </div>
  )
}

export default SmartExplain
