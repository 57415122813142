

const header = () => {
   


    
  return (
    <>
    {/* header-area */}
        <header id="header">
            <div id="sticky-header" className="menu-area menu-style-two transparent-header">
                <div className="container custom-container-three">
                    <div className="row">
                        <div className="col-12">
                            <div className="mobile-nav-toggler"></div>
                            <div className="menu-wrap">
                                <nav className="menu-nav">
                                    <div className="logo">
                                        <a href="index.html"><img src="/assets/img/logo/logo.png" alt=""/></a>
                                    </div>
                                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                                        
                                    </div>
                                    
                                        <ul>
                                            <li className="header-btn"><a href="SmartPage" className="btn">private sale</a></li>
                                        </ul>
                                    
                                </nav>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </header>
        {/* header-area-end */}
    </>
  )
}




export default header
