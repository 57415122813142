
import './Roadmap.css'
const Roadmap = () => {
  return (
    <div>
      <section id="roadmap" className="roadmap-area-two pt-130 pb-100">
                <div className="container custom-container-two">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-title section-title-two text-center mb-65">
                                <span className="sub-title">roadmap</span>
                                <h2 className="title">WTE Strategy and <br/> Project Plan</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="roadmap-wrap-two">
                                <div className="roadmap-item">
                                    <span className="roadmap-title">Mid of Q1 2024</span>
                                    <div className="roadmap-content">
                                        <span className="dot"></span>
                                        <p>• Presale
                                        <br/>
                                        • Listing
                                        <br/>
                                        • WTE NFT World beta version
                                        <br/>
                                        • WTE staking platform Beta
                                        version </p>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <span className="roadmap-title">Mid Q2 2024</span>
                                    <div className="roadmap-content">
                                        <span className="dot"></span>
                                        <p>• Profit sharing event
                                        <br/>
                                        • Tracking wallet Dapps Beta version</p>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <span className="roadmap-title">Mid Q3 2024</span>
                                    <div className="roadmap-content">
                                        <span className="dot"></span>
                                        <p>• Cex Platform Beta version</p>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <span className="roadmap-title">End of Q4 2024</span>
                                    <div className="roadmap-content">
                                        <span className="dot"></span>
                                        <p>
                                        • WTE NFT World Alpha version
                                        <br/>
                                        • WTE staking platform Alpha Version</p>
                                    </div>
                                </div>
                            </div>
                            <div className="roadmap-wrap-two bottom">
                                <div className="roadmap-item">
                                    <span className="roadmap-title">Mid of Q1 2025</span>
                                    <div className="roadmap-content">
                                        <span className="dot"></span>
                                        <p>
                                        • Tracking Version
                                        <br/>
                                        wallets
                                        <br/>
                                        alpha</p>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <span className="roadmap-title">Mid Q2 2025</span>
                                    <div className="roadmap-content">
                                        <span className="dot"></span>
                                        <p>• CEX platform alpha Version</p>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <span className="roadmap-title">Mid Q3 2025</span>
                                    <div className="roadmap-content">
                                        <span className="dot"></span>
                                        <p>
                                        • WTE NFT world
                                        <br/>
                                        • WTE staking platform Full versions
                                        <br/>
                                        • Mobile apps</p>
                                    </div>
                                </div>
                                <div className="roadmap-item">
                                    <span className="roadmap-title">End of Q4 2025</span>
                                    <div className="roadmap-content">
                                        <span className="dot"></span>
                                        <p>
                                        • WTE Tracking wallets
                                        <br/>
                                        • CEX platform Full versions
                                        <br/>
                                        • Mobile apps</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </div>
  )
}

export default Roadmap
